import React from 'react';
import { NavLink } from 'react-router-dom';
import { Home, FileText, Users, Settings, FileStack } from 'lucide-react';

const Sidebar = () => {
  return (
    <aside className="fixed left-0 top-0 h-screen w-64 bg-primary text-white">
      <div className="p-6">
        <h1 className="text-2xl font-bold mb-8">Papyrus</h1>
        
        <nav className="space-y-2">
          <NavLink 
            to="/dashboard" 
            className={({ isActive }) =>
              `flex items-center space-x-2 p-3 rounded-lg transition-colors ${
                isActive ? 'bg-white/10' : 'hover:bg-white/5'
              }`
            }
          >
            <Home className="w-5 h-5" />
            <span>Dashboard</span>
          </NavLink>

          <NavLink 
            to="/documents" 
            className={({ isActive }) =>
              `flex items-center space-x-2 p-3 rounded-lg transition-colors ${
                isActive ? 'bg-white/10' : 'hover:bg-white/5'
              }`
            }
          >
            <FileStack className="w-5 h-5" />
            <span>Documents</span>
          </NavLink>

          <NavLink 
            to="/forms" 
            className={({ isActive }) =>
              `flex items-center space-x-2 p-3 rounded-lg transition-colors ${
                isActive ? 'bg-white/10' : 'hover:bg-white/5'
              }`
            }
          >
            <FileText className="w-5 h-5" />
            <span>Forms</span>
          </NavLink>

          <NavLink 
            to="/users" 
            className={({ isActive }) =>
              `flex items-center space-x-2 p-3 rounded-lg transition-colors ${
                isActive ? 'bg-white/10' : 'hover:bg-white/5'
              }`
            }
          >
            <Users className="w-5 h-5" />
            <span>Users</span>
          </NavLink>

          <NavLink 
            to="/settings" 
            className={({ isActive }) =>
              `flex items-center space-x-2 p-3 rounded-lg transition-colors ${
                isActive ? 'bg-white/10' : 'hover:bg-white/5'
              }`
            }
          >
            <Settings className="w-5 h-5" />
            <span>Settings</span>
          </NavLink>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;