// src/App.js
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import './App.css';
import Layout from './components/Layout/Layout';
import Dashboard from './pages/Dashboard';
import Forms from './pages/Forms';
import Documents from './pages/Documents';
import Users from './pages/Users';
import Settings from './pages/Settings';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="/dashboard" replace />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="forms" element={<Forms />} />
          <Route path="documents" element={<Documents />} />
          <Route path="users" element={<Users />} />
          <Route path="settings" element={<Settings />} />
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;

// import React from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// // import Menu from "./pages/Menu";
// import Dashboard from "./pages/Dashboard"; 
// import Forms from "./pages/Forms"; 
// import Documents from "./pages/Documents"; 
// import Users from "./pages/Users"; 
// import Settings from "./pages/Settings"; 

// const App = () => {
//     return (
//         <Router>
//             <div>
//                 {/* <Menu /> */}
//                 <main>
//                     <Routes>
//                         <Route path="/dashboard" element={<Dashboard />} />
//                         <Route path="/forms" element={<Forms />} />
//                         <Route path="/documents" element={<Documents />} />
//                         <Route path="/users" element={<Users />} />
//                         <Route path="/settings" element={<Settings />} />
//                     </Routes>
//                 </main>
//             </div>
//         </Router>
//     );
// };

// export default App;


