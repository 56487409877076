//pages/Dashboard.js
import React from 'react';
import { BarChart2, Users, FileText, Database, TrendingUp, Clock } from 'lucide-react';

const DashboardCard = ({ title, value, icon, change, color }) => (
  <div className="bg-white p-6 rounded-lg shadow">
    <div className="flex justify-between items-start">
      <div>
        <p className="text-sm text-gray-600 mb-1">{title}</p>
        <h3 className="text-2xl font-bold text-gray-900">{value}</h3>
      </div>
      <div className={`p-3 rounded-full ${color}`}>
        {icon}
      </div>
    </div>
    {change && (
      <div className="mt-4 flex items-center">
        <TrendingUp className="w-4 h-4 text-green-500 mr-1" />
        <span className="text-sm text-green-500">{change}</span>
      </div>
    )}
  </div>
);

const RecentActivity = () => (
  <div className="bg-white p-6 rounded-lg shadow">
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-lg font-semibold">Recent Activity</h2>
      <button className="text-sm text-primary hover:underline">View all</button>
    </div>
    <div className="space-y-4">
      {[1, 2, 3].map((item) => (
        <div key={item} className="flex items-center gap-4 border-b pb-4">
          <Clock className="w-5 h-5 text-gray-400" />
          <div>
            <p className="text-sm font-medium">New document uploaded</p>
            <p className="text-xs text-gray-500">2 hours ago</p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const Dashboard = () => {
  const stats = [
    {
      title: "Total Users",
      value: "1,234",
      icon: <Users className="w-6 h-6 text-white" />,
      change: "+12.3% from last month",
      color: "bg-blue-500"
    },
    {
      title: "Active Forms",
      value: "45",
      icon: <FileText className="w-6 h-6 text-white" />,
      change: "+8.1% from last month",
      color: "bg-green-500"
    },
    {
      title: "Documents",
      value: "3,456",
      icon: <Database className="w-6 h-6 text-white" />,
      change: "+23.1% from last month",
      color: "bg-purple-500"
    },
    {
      title: "Analytics",
      value: "+12.3%",
      icon: <BarChart2 className="w-6 h-6 text-white" />,
      change: "+4.2% from last month",
      color: "bg-orange-500"
    }
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Dashboard</h1>
        <button className="btn btn-primary">Download Report</button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {stats.map((stat, index) => (
          <DashboardCard key={index} {...stat} />
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <RecentActivity />
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4">Quick Actions</h2>
          <div className="grid grid-cols-2 gap-4">
            <button className="p-4 border rounded-lg hover:bg-gray-50 text-left">
              <FileText className="w-6 h-6 text-primary mb-2" />
              <h3 className="font-medium">New Form</h3>
              <p className="text-sm text-gray-500">Create a new form</p>
            </button>
            <button className="p-4 border rounded-lg hover:bg-gray-50 text-left">
              <Database className="w-6 h-6 text-primary mb-2" />
              <h3 className="font-medium">Upload Document</h3>
              <p className="text-sm text-gray-500">Add new document</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;