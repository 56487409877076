// pages/Forms.js
import React, { useState } from 'react';
import { Plus, Search, Filter, MoreVertical } from 'lucide-react';

const FormCard = ({ title, description, lastModified, status }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow hover:shadow-md transition-shadow">
      <div className="flex justify-between items-start">
        <div>
          <h3 className="font-medium text-lg text-gray-900">{title}</h3>
          <p className="text-sm text-gray-500 mt-1">{description}</p>
        </div>
        <button className="p-2 hover:bg-gray-100 rounded">
          <MoreVertical className="w-5 h-5 text-gray-400" />
        </button>
      </div>
      <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <span className={`px-2 py-1 text-xs rounded ${
            status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
          }`}>
            {status}
          </span>
          <span className="text-sm text-gray-500">Last modified: {lastModified}</span>
        </div>
      </div>
    </div>
  );
};

const Forms = () => {
  // const [view, setView] = useState('grid');
  const [searchTerm, setSearchTerm] = useState('');

  const forms = [
    {
      id: 1,
      title: "Employee Onboarding",
      description: "New employee registration and documentation form",
      lastModified: "2 days ago",
      status: "Active"
    },
    {
      id: 2,
      title: "Leave Request",
      description: "Staff leave application form",
      lastModified: "5 days ago",
      status: "Active"
    },
    {
      id: 3,
      title: "Expense Claim",
      description: "Monthly expense reimbursement form",
      lastModified: "1 week ago",
      status: "Draft"
    },
  ];

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Forms</h1>
        <button className="btn btn-primary flex items-center gap-2">
          <Plus className="w-5 h-5" />
          Create New Form
        </button>
      </div>

      {/* Search and Filters */}
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="relative flex-1">
          <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
          <input
            type="text"
            placeholder="Search forms..."
            className="pl-10 pr-4 py-2 border rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-primary"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="flex gap-2">
          <button className="flex items-center gap-2 px-4 py-2 border rounded-lg hover:bg-gray-50">
            <Filter className="w-5 h-5" />
            Filters
          </button>
          <select className="px-4 py-2 border rounded-lg bg-white">
            <option>Latest</option>
            <option>Oldest</option>
            <option>Name A-Z</option>
            <option>Name Z-A</option>
          </select>
        </div>
      </div>

      {/* Forms Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {forms.map((form) => (
          <FormCard key={form.id} {...form} />
        ))}
      </div>
    </div>
  );
};

export default Forms;